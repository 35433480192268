import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from '@/assets/js/navigationUtils';

const applyRoutes = [
    {
        path: '/apply',
        component: LxpLayout,
        children: [
            {
                path: 'train',
                name: "ApplyTrain",
                component: () => import('../../views/pages/apply/ApplyTrain'),
            },
            {
                path: 'train/:distCrseSn',
                name: "ApplyTrainView",
                component: () => import('../../views/pages/apply/ApplyTrainView'),
            },
            {
                path: 'annual',
                name: "ApplyAnnualMain",
                component: () => import('../../views/pages/apply/ApplyAnnualMain'),
            },
            {
                path: 'annual-cyber',
                name: "ApplyAnnual",
                component: () => import('../../views/pages/apply/ApplyAnnual'),
            },
            {
                path: 'status',
                name: "ApplyStatus",
                component: () => import('../../views/pages/apply/ApplyStatus'),
            },
            {
                path: 'dept-req',
                name: "ApplyDeptReqTrain",
                component: () => import('../../views/pages/apply/ApplyDeptReqTrain'),
            },
            {
                path: 'dept-reg',
                name: "ApplyDeptReqTrainReg",
                component: () => import('../../views/pages/apply/ApplyDeptReqTrainReg'),
            },
        ],
    }
];

const mobileApplyRoutes = [
    {
        path: '/apply',
        component: () => import('../../views/layout/lxp/mobile/LxpMobileLayout'),
        children: [
            {
                path: 'train',
                name: "ApplyTrain",
                component: () => import('../../views/pages/apply/ApplyTrain'),
            },
            {
                path: 'train/:distCrseSn',
                name: "ApplyTrainView",
                component: () => import('../../views/pages/apply/ApplyTrainView'),
            },
            {
                path: 'annual',
                name: "ApplyAnnualMain",
                component: () => import('../../views/pages/apply/mobile/ApplyAnnualMobileMain'),
            },
            {
                path: 'annual-cyber',
                name: "ApplyAnnual",
                component: () => import('../../views/pages/apply/ApplyAnnual'),
            },
            {
                path: 'status',
                name: "ApplyStatus",
                component: () => import('../../views/pages/apply/ApplyStatus'),
            },
            {
                path: 'agree/:distCrseSn',
                name: "ApplyAgree",
                component: () => import('../../views/pages/apply/mobile/ApplyAgree'),
            },
            {
                path: 'status/:distCrseSn',
                name: "ApplyStatusEdit",
                component: () => import('../../views/pages/apply/mobile/ApplyStatusEdit'),
            },
            {
                path: 'cntst/:distCrseSn',
                name: "ApplyCntst",
                component: () => import('../../views/pages/apply/mobile/ApplyCntst'),
            },
            {
                path: 'agreement/ci',
                name: "CIAgreement",
                component: () => import('../../views/pages/apply/mobile/MobileCIAgreementModal'),
            },
        ],
    }
];

export const setApplyRoutes = (routes) => {
    if (navigationUtils.any()) {
        routes.push(...mobileApplyRoutes);
    } else {
        routes.push(...applyRoutes);
    }
}