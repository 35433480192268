import ApiService from '@/assets/js/api.service';

// insert
export const ACT_INSERT_LRN_SPRT_ACAD = 'actInsertLearnSupportAcademy';
export const ACT_INSERT_LRN_SPRT_EVL = 'actInsertLearnSupportEvaluation';
export const ACT_INSERT_LRN_SPRT_SCHL = 'actInsertLearnSupportSchool';
export const ACT_INSERT_LRN_SPRT_LCSN = 'actInsertLearnSupportLicense';
export const ACT_INSERT_LRN_SPRT_OFFCR = 'actInsertLearnSupportOfficer';
export const ACT_INSERT_LEARN_SUPPORT_LICENSE_RENEW = 'insertLearnSupportLicenseRenew';

// update
export const ACT_UPDATE_LEARN_SUPPORT_LICENSE_RENEW = 'actUpdateLearnSupportLicenseRenew';
export const ACT_UPDATE_LEARN_SUPPORT_ACADEMY = 'actUpdateLearnSupportAcademy';
export const ACT_UPDATE_LEARN_SUPPORT_EVALUATION = 'actUpdateLearnSupportEvaluation';
export const ACT_UPDATE_LEARN_SUPPORT_SCHOOL = 'actUpdateLearnSupportSchool';
export const ACT_UPDATE_LEARN_SUPPORT_OFFICER = 'actUpdateLearnSupportOfficer';
export const ACT_UPDATE_LEARN_SUPPORT_LICENSE = 'actUpdateLearnSupportLicense';

export const ACT_DELETE_CANCEL_LRN_SPRT_ACAD = 'deleteCancelLrnSprtAcad';
export const ACT_DELETE_CANCEL_LRN_SPRT_EVL = 'deleteCancelLrnSprtEvl';
export const ACT_DELETE_CANCEL_LRN_SPRT_SCHL = 'deleteCancelLrnSprtSchl';
export const ACT_DELETE_CANCEL_LRN_SPRT_OFFCR = 'deleteCancelLrnSprtOffcr';
export const ACT_DELETE_CANCEL_LRN_SPRT_LCSN = 'deleteCancelLrnSprtLcsn';


// get
export const ACT_GET_LRN_COST_TY = 'actGetLearnCostType';
export const ACT_GET_LRN_COST_FLD = 'actGetLearnCostField';
export const ACT_GET_LRN_SPRT_LCSN_RENEW_LIST = 'actGetLrnSprtLcsnRenewList';
export const ACT_GET_LRN_SPRT_LCSN_RENEW_LIST2 = 'actGetLrnSprtLcsnRenewList2';
export const ACT_GET_LRN_SPRT_LCSN_RENEW_HISTORY_LIST = 'actGetLrnSprtLcsnRenewHistoryList';
export const ACT_GET_LEARN_SUPPORT_ACADEMY_LIST = 'actGetLearnSupportAcademyList';
export const ACT_GET_LEARN_SUPPORT_EVALUATION_LIST = 'actGetLearnSupportEvaluationList';
export const ACT_GET_LEARN_SUPPORT_SCHOOL_LIST = 'actGetLearnSupportSchoolList';
export const ACT_GET_LEARN_SUPPORT_OFFICER_LIST = 'actGetLearnSupportOfficerList';
export const ACT_GET_LEARN_SUPPORT_LICENSE_LIST = 'actGetLearnSupportLicenseList';
export const ACT_GET_LEARN_SUPPORT_LICENSE_RENEWAL_LIST = 'actGetLearnSupportLicenseRenewalList';
export const ACT_GET_LEARN_SUPPORT_ACADEMY = 'actGetLearnSupportAcademy';
export const ACT_GET_LEARN_SUPPORT_ACADEMY_TOTAL = 'actGetLearnSupportAcademyTotal';
export const ACT_GET_LEARN_SUPPORT_EVALUATION = 'actGetLearnSupportEvaluation';
export const ACT_GET_LEARN_SUPPORT_EVAL_TOTAL = 'actGetLearnSupportEvalTotal';
export const ACT_GET_LEARN_SUPPORT_SCHOOL_NOT_END_CNT = 'actGetLearnSupportSchoolNotEndCnt';
export const ACT_GET_LEARN_SUPPORT_SCHOOL = 'actGetLearnSupportSchool';
export const ACT_GET_LEARN_SUPPORT_OFFICER = 'actGetLearnSupportOfficer';
export const ACT_GET_LEARN_SUPPORT_LICENSE = 'actGetLearnSupportLicense';
export const ACT_GET_SUPPORT_LICENSE_HISTORY_LIST = 'actGetSupportLicenseHistoryList';
export const ACT_GET_SUPPORT_LICENSE_ALL_HISTORY_LIST = 'actGetSupportLicenseAllHistoryList';
export const ACT_GET_RST_REG_CHK = 'actGetRstRegChk';
export const ACT_GET_DSJ_CHK = 'actGetDsjChk';
export const ACT_GET_CLASS_DSJ_CHK = 'actGetClassDsjChk';
export const ACT_GET_REFUND_CHK = 'actGetRefundChk';
export const ACT_GET_JNCMP_YMD = 'actGetJncmpYmd';

export const ACT_GET_BEF_TERM_BURS_AMT = 'actGetBefTermBursAmt';

export const ACT_GET_LRN_SPRT_EVL_APRVD_LIST = 'actGetLrnSprtEvlAprvdList';
export const ACT_GET_LRN_REFUND_LIST = 'actGetLrnRefundList';
export const ACT_GET_LEARN_SUPPORT_SCHOOL_SPRT_CREDIT = 'actGetLearnSupportSchoolSprtCredit'

export const ACT_GET_EVL_SPRT_AMT_INFO = 'actGetEvlSprtAmtInfo';

export const ACT_GET_EVL_LCSN_DUP_CHK = 'actGetEvlLcsnDupChk';

export const ACT_GET_SCHL_CREDIT_APPLY_AVL_CHK = 'actGetSchlCreditApplyAvlChk';

export const ACT_GET_ACAD_GRD_LCSN = 'actGetAcadGrdLcsn';
// export const ACT_SEND_TMP_MSG = 'actSendTmpMsg';

export const ACT_GET_ACAD_LMT_AMT = 'actGetAcadLmtAmt';

export const MUT_SHOW_SUPPORT = 'showSupport';
export const MUT_CLOSE_SUPPORT = 'closeSupport';
export const MUT_SHOW_LICENSE_MAIN = 'showLicenseMain';
export const MUT_HIDE_LICENSE_MAIN = 'closeLicenseMain';
export const MUT_SHOW_EVL_MAIN = 'showEvlMain';
export const MUT_HIDE_EVL_MAIN = 'closeEvlMain';

export const MUT_SHOW_CLASS_MAIN = 'showClassMain';
export const MUT_HIDE_CLASS_MAIN = 'closeClassMain';
export const MUT_SHOW_MAIN = 'showSupportMain';
export const MUT_CLOSE_MAIN = 'closeSupportMain';

export const MUT_SHOW_HEADER = 'showHeader';
export const MUT_HIDE_HEADER = 'hideHeader';


const state = {
  main: {
    show: true
  },
  isSupport: false,
  isLicenseMain: true,
  isHeader: true
};

const mutations = {
  [MUT_SHOW_MAIN](state) {
    state.main.show = true;
  },
  [MUT_CLOSE_MAIN](state) {
    state.main.show = false;
  },
  [MUT_SHOW_SUPPORT](state){
    state.isSupport = true;
  },
  [MUT_CLOSE_SUPPORT](state){
    state.isSupport = false;
  },
  [MUT_SHOW_LICENSE_MAIN](state){
    state.isLicenseMain = true;
  },
  [MUT_HIDE_LICENSE_MAIN](state){
    state.isLicenseMain = false;
  },
  [MUT_SHOW_EVL_MAIN](state){
    state.isEvlMain = true;
  },
  [MUT_HIDE_EVL_MAIN](state){
    state.isEvlMain = false;
  },
  [MUT_SHOW_CLASS_MAIN](state){
    state.isClassMain = true;
  },
  [MUT_HIDE_CLASS_MAIN](state){
    state.isClassMain = false;
  },
  [MUT_SHOW_HEADER](){
    state.isHeader = true;
  },
  [MUT_HIDE_HEADER](){
    state.isHeader = false;
  }
};

const actions = {
  [ACT_INSERT_LRN_SPRT_ACAD](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/support/academy', params).then(response => resolve(response))});
  },
  [ACT_INSERT_LRN_SPRT_EVL](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/support/eval', params).then(response => resolve(response))});
  },
  [ACT_INSERT_LRN_SPRT_SCHL](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/support/schl',params).then(response => resolve(response))});
  },
  [ACT_INSERT_LRN_SPRT_OFFCR](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/support/offcr', params).then(response => resolve(response))});
  },
  [ACT_INSERT_LRN_SPRT_LCSN](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/support/license', params).then(response => resolve(response))});
  },
  [ACT_INSERT_LEARN_SUPPORT_LICENSE_RENEW](context, params) {
    return new Promise(resolve => {
      ApiService.post('/v1/app/support/license/renew', params).then(response => resolve(response))});
  },
  [ACT_UPDATE_LEARN_SUPPORT_LICENSE_RENEW](context, {costLcsnSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/support/license/${costLcsnSn}/renew`, params).then(response => resolve(response))});
  },
  [ACT_GET_LRN_COST_TY](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/cost/ty', params).then(response => resolve(response))});
  },
  [ACT_GET_LRN_COST_FLD](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/cost/fld', params).then(response => resolve(response))});
  },

  [ACT_GET_LRN_SPRT_LCSN_RENEW_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/license/renewal', params).then(response => resolve(response))});
  },
  [ACT_GET_LRN_SPRT_LCSN_RENEW_LIST2](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/license/renewal2', params).then(response => resolve(response))});
  },
  [ACT_GET_LRN_SPRT_LCSN_RENEW_HISTORY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/license/renewal/hstoies', params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_ACADEMY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/academies', params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_EVALUATION_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/evaluations', params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_SCHOOL_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/schools', params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_OFFICER_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/officers', params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_LICENSE_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/licenses', params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_LICENSE_RENEWAL_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/renewals', params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_ACADEMY](context, {costAcdmFeeSn, params}) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/acad/${costAcdmFeeSn}`, params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_ACADEMY_TOTAL](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/academy/total`, params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_EVALUATION](context, {costEvlSn, params}) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/eval/${costEvlSn}`, params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_EVAL_TOTAL](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/eval/total`, params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_SCHOOL_NOT_END_CNT](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/schl/notEndCnt`, params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_SCHOOL](context, {costTuitFeeSn, params}) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/schl/${costTuitFeeSn}`, params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_OFFICER](context, {costOffcrSn, params}) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/offcr/${costOffcrSn}`, params).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_LICENSE](context, {costLcsnSn, params}) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/lcsn/${costLcsnSn}`, params).then(response => resolve(response))});
  },
  [ACT_UPDATE_LEARN_SUPPORT_ACADEMY](context, {costAcdmFeeSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/support/academy/${costAcdmFeeSn}`, params).then(response => resolve(response))});
  },
  [ACT_UPDATE_LEARN_SUPPORT_EVALUATION](context, {costEvlSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/support/eval/${costEvlSn}`, params).then(response => resolve(response))});
  },
  [ACT_UPDATE_LEARN_SUPPORT_SCHOOL](context, {costTuitFeeSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/support/schl/${costTuitFeeSn}`, params).then(response => resolve(response))});
  },
  [ACT_UPDATE_LEARN_SUPPORT_OFFICER](context, {costOffcrSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/support/offcr/${costOffcrSn}`, params).then(response => resolve(response))});
  },
  [ACT_UPDATE_LEARN_SUPPORT_LICENSE](context, {costLcsnSn, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/support/lcsn/${costLcsnSn}`, params).then(response => resolve(response))});
  },
  [ACT_GET_SUPPORT_LICENSE_HISTORY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/license/history', params).then(response => resolve(response))
    });
  },
  [ACT_GET_SUPPORT_LICENSE_ALL_HISTORY_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/license/all/history', params).then(response => resolve(response))
    });
  },
  [ACT_DELETE_CANCEL_LRN_SPRT_ACAD](context, costAcdmFeeSn){
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/support/acad`, costAcdmFeeSn).then(response => resolve(response))
    });
  },
  [ACT_DELETE_CANCEL_LRN_SPRT_EVL](context, costAcdmFeeSn){
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/support/eval`, costAcdmFeeSn).then(response => resolve(response))
    });
  },
  [ACT_DELETE_CANCEL_LRN_SPRT_SCHL](context, costTuitFeeSn){
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/support/schl`, costTuitFeeSn).then(response => resolve(response))
    });
  },
  [ACT_DELETE_CANCEL_LRN_SPRT_OFFCR](context, costOffcrSn){
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/support/offcr`, costOffcrSn).then(response => resolve(response))
    });
  },
  [ACT_DELETE_CANCEL_LRN_SPRT_LCSN](context, costLcsnSn){
    return new Promise(resolve => {
      ApiService.delete(`/v1/app/support/lcsn`, costLcsnSn).then(response => resolve(response))
    });
  },
  [ACT_GET_BEF_TERM_BURS_AMT](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/schl/befTba`, params).then(response => resolve(response))});
  },
  [ACT_GET_LRN_SPRT_EVL_APRVD_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/evl/aprvd`, params).then(response => resolve(response))});
  },
  [ACT_GET_LRN_REFUND_LIST](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/refund`, params).then(response => resolve(response))});
  },
  [ACT_GET_RST_REG_CHK](){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/academies/rstRegChk`).then(response => resolve(response))});
  },
  [ACT_GET_DSJ_CHK](){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/dsjChk`).then(response => resolve(response))});
  },
  [ACT_GET_CLASS_DSJ_CHK](){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/classDsjChk`).then(response => resolve(response))});
  },
  [ACT_GET_JNCMP_YMD](){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/jncmpYmd`).then(response => resolve(response))});
  },
  [ACT_GET_REFUND_CHK](){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/refundChk`).then(response => resolve(response))});
  },
  [ACT_GET_LEARN_SUPPORT_SCHOOL_SPRT_CREDIT](context, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/schl/sprtCredit`, params).then(response => resolve(response))});
  },
  [ACT_GET_EVL_SPRT_AMT_INFO](context, strYear) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/evl/${strYear}`).then(response => resolve(response))});
  },
  [ACT_GET_EVL_LCSN_DUP_CHK](context, lrnCostFldSn){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/evl/dupChk/${lrnCostFldSn}`).then(response => resolve(response))});
  },
  [ACT_GET_SCHL_CREDIT_APPLY_AVL_CHK](){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/support/schl/creditApplyAvlChk`).then(response => resolve(response))});
  },
  [ACT_GET_ACAD_GRD_LCSN](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/cost/acadGrdLcsn', params).then(response => resolve(response))});
  },
  // [ACT_SEND_TMP_MSG](context, {templateSn, params}){
  //   return new Promise(resolve => {
  //     ApiService.post(`/v1/admin/notis/template/${templateSn}/send`, params).then(response => resolve(response));
  //   });
  // }
  [ACT_GET_ACAD_LMT_AMT](context, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/acad/limitAmt', params).then(response => resolve(response))});
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
