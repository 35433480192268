<template>
  <!-- page-container -->
  <div class="page-container">
    <div class="page-top">
      <div class="page-component">
        <div class="top-nav">
<!--          <router-link :to="{name: 'SSLHome'}" class="top-kb-nav-link">-->
<!--            <span class="ic-bg"><i class="icon-type-back"></i></span>-->
<!--            <span class="text">SSL 홈</span>-->
<!--          </router-link>-->
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="myssl-container">
          <div class="myssl-top">
            <div class="top-my">
              <div class="my-avatar">
                <!--      <i class="icon-grade-5"></i>-->
                <div class="avatar">
                  <ProfileImg
                      :target="session"
                  />
                </div>
              </div>
              <div class="my-title">
                <h2 class="title">
                  <img src="@/assets/lxp/images/ssl/myssl/ic_myssl.svg" alt/>
                </h2>
              </div>
            </div>

            <div class="top-tabs" style="margin-top: 0">
              <template v-for="(item, idx) in items" :key="idx">
                <router-link
                    :to="item.to"
                    class="tab" :class="{'is-active': item.key === activeKey}"
                >
                  <span class="tab-text">{{ item.name }}</span>
                </router-link>
              </template>
            </div>
          </div>

        <div class="list-top">
          <div class="top-column">
            <!-- list-top > list-sort -->
            <div class="list-sort">
              <div class="sort">
                <SortButtonSelect
                    v-model="filters.year"
                    v-model:toggle="toggles.year"
                    title="년도"
                    :options="yearOptions"
                    sequence-key="value"
                    name-key="display"
                    :is-num="true"
                    :is-all="false"
                    @update:toggle="closeToggles(toggles, 'year')"
                    @selected="getOptions()"
                />
                <!-- //kb-form-dropdown -->
              </div>

              <div class="sort">
                <SortButtonSelect
                    v-model="filters.distCrseSn"
                    v-model:toggle="toggles.distCrseSn"
                    title="과정"
                    :options="distOptions"
                    sequence-key="distCrseSn"
                    name-key="crseNm"
                    :is-all="true"
                    :width="300"
                    @update:toggle="closeToggles(toggles, 'distCrseSn')"
                    @selected="initSearch()"
                />
              </div>
            </div>
          </div>
        </div>

        <SSLTotBoardList
            :is-loading="isLoading"
            :items="boardItems"
            :paging="paging"
            :more-func="moreFunc">
          <template v-slot:search>
              <div class="top-column">
                <div class="kb-form-field field-search">
                  <input v-model.trim="keyword" type="text" class="kb-form-control" name="" placeholder="검색" @keyup.enter="initSearch()"/>
                  <button class="kb-btn-search" @click="initSearch()">
                    <i class="icon-search"></i>
                  </button>
                  <button class="kb-btn-search-delete" @click="deleteKeyword">
                    <i class="icon-delete"></i>
                  </button>
                </div>
              </div>
          </template>
        </SSLTotBoardList>
      </div>

    </div>
    <!-- //page-footer -->
  </div>
  <!-- //page-container -->

</template>

<script>
import {sslTotalMySetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import ProfileImg from "@/components/common/ProfileImg.vue";
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";
import SSLTotBoardList from '@/components/ssl/SSLTotBoardList';

export default {
  name: 'SSLTotalMy',
  components: {
    SortButtonSelect,
    ProfileImg,
    SSLTotBoardList
  },
  setup: sslTotalMySetup
}
</script>
