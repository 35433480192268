export const viewCommentUpdate = '댓글이 수정되었습니다.';
export const viewCommentUpdateError = '정상적인 댓글 수정이 아닙니다. 관리자에게 문의하세요.';
export const viewCommentDelete = '댓글이 삭제되었습니다.';
export const viewCommentEditConfirm = (edit) => {
  return `댓글을 ${edit}하시겠습니까?`;
}
export const hrdLikeTitle = `좋아요`
export const hrdLike = `좋아요를 하시겠습니까?`;
export const hrdCancelLike = `이미 좋아요를 누르셨습니다.`;

export const hrdPutTitle = `담기`;
export const hrdPut = '콘텐츠를 담으시겠습니까?';
export const hrdCancelPut = '담기 취소되었습니다.';
export const hrdPutGoToPage = `콘텐츠가 목록에 담겼습니다.<br>지금 확인하시겠습니까?`;
export const hrdPutGoToMyPage = `콘텐츠가 My콘텐츠 목록에 담겼습니다.<br>지금 확인하시겠습니까?`;
export const hrdGroupPutGoToGroupPage = `콘텐츠가 그룹러닝 콘텐츠 목록에 담겼습니다.<br>지금 확인하시겠습니까?`;
export const hrdPutGoToMyPageBtnTitle = '보러가기';
export const goToHrdBtnTitle = 'My HRD';
export const goToGroupBtnTitle = '그룹러닝';

export const hrdSubscribeTitle = `구독`;
export const hrdSubscribe = `<br><br>의 카테고리를 구독하시겠습니까?`;
export const hrdCancelSubscribe = `이미 구독중입니다.`;
export const hrdSubscribeComplete = `구독 설정이 완료되었습니다.<br>구독 설정한 카테고리에 신규 콘텐츠가 등록되면<br>알림이 발송됩니다.`
export const hrdSubscribeGoToMyPageBtnTitle = '보러가기';