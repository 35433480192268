<template>
  <div class="popup-container" :class="{ 'is-active' : modelValue }" @click="closeToggles(toggles)">
    <div class="popup" id="popup-tuition-support-p022">
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">평가비 지원신청</h3>
        </div>

        <div class="popup-content">
          <div class="survey-container">
            <article class="popup-section section-form section-agree">
              <header class="section-header header-divider-v2">
                <h4 class="title">신청정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <Applicant />
                    <InputSelect
                        v-model:value1="param.lrnCostDivSn"
                        v-model:toggle1="toggles.lrnCostDivs"
                        title="평가구분 및 평가명"
                        :options1="lrnCostDivs"
                        sequence-key1="lrnCostDivSn"
                        name-key1="lrnCostDivNm"
                        placeholder1="평가구분을 선택해주세요"
                        :disabled="isReadOnly"
                        :useSubText="false"
                        @update:toggle1="closeToggles(toggles, 'lrnCostDivs')"
                    >
                      <template v-slot:search-func>
                        <div class="kb-form-select">
                          <InputSearch v-if="param.lrnCostDivSn == '4'" title="" v-model="license" v-model:inputNm="license.lrnCostFldNm" placeholder="자격증명 검색" :func="searchModalFunc" />
                          <InputSearch v-else title="" v-model="license" v-model:inputNm="license.lrnCostFldNm" placeholder="평가명 검색" :func="searchModalFunc" />
                        </div>
                      </template>
                    </InputSelect>

                  <RowDatepicker
                      v-model="param.evlExamDd"
                      v-model:toggle="toggles.evlExamDd"
                      title="평가응시일"
                      sequence-key="nm"
                      name-key="nm"
                      placeholder1="평가응시일을 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle="closeToggles(toggles, 'evlExamDd')"
                      :width="372"
                  />
                  <template v-if="param.lrnCostDivSn < 4">
                    <template v-if="param.lrnCostFldSn == '106'">
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <strong class="kb-form-label-text">성적</strong>
                          </label>
                        </div>
                        <div class="kb-form-column">LC: <input type="text" class="kb-form-control w-25" v-model="toeic.evlLcScore"  @input="numChk" /> / RC: <input type="text" class="kb-form-control w-25" v-model="toeic.evlRcScore"  @input="numChk" />
                        </div>
                      </div>
                    </template>
                    <template v-else-if="param.lrnCostFldSn == '243'">
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <strong class="kb-form-label-text">성적</strong>
                          </label>
                        </div>
                        <div class="kb-form-column">Speaking : <input type="text" class="kb-form-control w-25" v-model="toeic.evlLcScore" @input="numChk" /> / Writing : <input type="text" class="kb-form-control w-25" v-model="toeic.evlRcScore"  @input="numChk" />
                        </div>
                      </div>
                      <div class="kb-form-row">
                        <div class="kb-form-column kb-form-column-title">
                          <label class="kb-form-label">
                            <strong class="kb-form-label-text">등급</strong>
                          </label>
                        </div>
                        <div class="kb-form-column">Speaking : <input type="text" class="kb-form-control w-25" v-model="toeic.evlSGrade"/> / Writing : <input type="text" class="kb-form-control w-25" v-model="toeic.evlWGrade" />
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <RowInputText v-model="param.evlScore" class-lst="column-whole" title="성적" placeholder="성적을 입력해주세요(숫자만 가능)" :isNumber="true" :isFloat="true" :disabled="isReadOnly" />
                      <RowInputText v-model="param.evlGrade" class-lst="column-whole" title="등급" placeholder="등급이 있는 평가인 경우 입력해주세요" :disabled="isReadOnly" />
                    </template>
                    <Upload
                        v-model="lrnCertFiles"
                        title="성적증명서"
                        placeholder="공식 성적증명서를 등록하세요."
                        btn-title="성적증명서 등록하기"
                        :extensions="extensions"
                        :max-qty="10"
                        sequence-key="proofDataSn"
                        :disabled="isReadOnly"
                    />
                  </template>
                  <template v-else-if="param.lrnCostDivSn === 4 && (param.lrnCostFldSn == '268' || param.lrnCostFldSn == '269')">
                    <RowInputText v-model="param.evlScore" class-lst="column-whole" title="성적" placeholder="성적을 입력해주세요(숫자만 가능)" :isNumber="true" :isFloat="true" :disabled="isReadOnly" />
                    <!--                    <RowInputText v-model="param.evlGrade" class-lst="column-whole" title="등급" placeholder="등급이 있는 평가인 경우 입력해주세요" :disabled="isReadOnly" />-->
                    <InputSelect v-if="param.lrnCostFldSn == '268'"
                        v-model:value1="param.evlGrade"
                        v-model:toggle1="toggles.gradePccp"
                        title="등급"
                        :options1="gradePccp"
                        sequence-key1="val"
                        nameKey1="nm"
                        placeholder1="등급을 선택해주세요"
                        :disabled="isReadOnly"
                        @update:toggle1="closeToggles(toggles, 'gradePccp')"
                    />
                    <InputSelect v-else
                       v-model:value1="param.evlGrade"
                       v-model:toggle1="toggles.gradePcce"
                       title="등급"
                       :options1="gradePcce"
                       sequence-key1="val"
                       nameKey1="nm"
                       placeholder1="등급을 선택해주세요"
                       :disabled="isReadOnly"
                       @update:toggle1="closeToggles(toggles, 'gradePcce')"
                    />
                    <RowDatepicker
                        title="자격증취득일"
                        v-model="param.lcsnAcqsDd"
                        v-model:toggle="toggles.lcsnAcqsDd"
                        placeholder="자격증취득일을 선택해주세요"
                        :disabled="isReadOnly"
                        @update:toggle="closeToggles(toggles, 'lcsnAcqsDd')"
                    />
                    <Upload
                        v-model="lrnCertFiles"
                        title="자격증 사본 "
                        placeholder="자격증 사본을 등록하세요"
                        btn-title="자격증 사본 등록하기"
                        sequence-key="proofDataSn"
                        :extensions="extensions"
                        :disabled="isReadOnly"
                        :max-qty="10"
                    />
                  </template>
                  <!--IT구분 > 평가명 KBI선택시-->
                  <template v-else-if="param.lrnCostDivSn == 4 && param.lrnCostFldSn == '448'">
                    <RowDatepicker
                        title="자격증취득일"
                        v-model="param.lcsnAcqsDd"
                        v-model:toggle="toggles.lcsnAcqsDd"
                        placeholder="자격증취득일을 선택해주세요"
                        :disabled="isReadOnly"
                        @update:toggle="closeToggles(toggles, 'lcsnAcqsDd')"
                    />
                    <InputSelect
                        v-model:value1="param.evlGrade"
                        v-model:toggle1="toggles.gradeDT"
                        title="등급"
                        :options1="gradeDT"
                        sequence-key1="val"
                        nameKey1="nm"
                        placeholder1="등급을 선택해주세요."
                        :disabled="isReadOnly"
                        @update:toggle1="closeToggles(toggles, 'gradeDT')"
                    />
                    <Upload
                        v-model="lrnCertFiles"
                        title="자격증"
                        placeholder="자격증을 등록해주세요"
                        btn-title="자격증을 등록하기"
                        sequence-key="proofDataSn"
                        :extensions="extensions"
                        :disabled="isReadOnly"
                        :max-qty="10"
                    />

                  </template>



                  <template v-else>
                    <RowDatepicker
                        title="자격증취득일"
                        v-model="param.lcsnAcqsDd"
                        v-model:toggle="toggles.lcsnAcqsDd"
                        placeholder="자격증취득일을 선택해주세요"
                        :disabled="isReadOnly"
                        @update:toggle="closeToggles(toggles, 'lcsnAcqsDd')"
                    />
                    <Upload
                        v-model="lrnCertFiles"
                        title="자격증"
                        placeholder="자격증을 등록해주세요"
                        btn-title="자격증을 등록하기"
                        sequence-key="proofDataSn"
                        :extensions="extensions"
                        :disabled="isReadOnly"
                        :max-qty="10"
                    />

<!--                    <RowInputText v-model="param.examFee" :is-number="true" :is-money="true" title="응시료" placeholder="응시료를 입력하세요" :disabled="isReadOnly" />-->
                  </template>
                  <InputSelect
                      v-model:value1="param.costAplyYn"
                      v-model:toggle1="toggles.costAplies"
                      title="비용지원신청여부"
                      :options1="costAplies"
                      sequence-key1="val"
                      nameKey1="nm"
                      placeholder1="비용지원신청여부를 선택해주세요"
                      :disabled="isReadOnly"
                      @update:toggle1="closeToggles(toggles, 'costAplies')"
                  />
                  <RowInputText
                      v-if="param.costAplyYn === 'Y'"
                      v-model="param.examFee"
                      :is-number="true"
                      :is-money="true"
                      :disabled="isReadOnly"
                      title="응시료"
                      placeholder="응시료를 입력하세요"
                  />
                </div>
              </div>
            </article>

            <article class="popup-section section-agree">
              <header class="section-header header-divider-v2">
                <h4 class="title">지원정보</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">지원 한도액</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input type="text" class="kb-form-control" :value="`${numberComma(supportEvlYearlyLimit)}`" readonly />
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">지원 누계액</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input type="text" class="kb-form-control" :value="`${numberComma(sprtAmtInfo.sprtAmt)}`" readonly />
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">현재신청중 누계액</strong>
                      </label>
                    </div>
                    <div class="kb-form-column column-whole">
                      <input type="text" class="kb-form-control" :value="`${numberComma(sprtAmtInfo.examFee)}`" readonly />
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <Payment
                v-if="isInit && param.costAplyYn === 'Y'"
                v-model="paymentInfo"
                v-model:card-kinds-toggle="toggles.cardKind"
                v-model:card-aprv-dt-toggle="toggles.cardAprvDt"
                v-model:files="receiptFiles"
                :disabled="isReadOnly"
                @update:modelValue="updatePaymentInfo(paymentInfo)"
            />

            <article class="popup-section section-agree" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
              <header class="section-header header-divider-v2">
                <h4 class="title">환입 및 추가지원금액</h4>
              </header>
              <div class="section-content">
                <div class="kb-form-fields kb-form-fields-v2">
                  <RowInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
                  <RowInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
                </div>
              </div>
            </article>

            <article v-if="data == null" class="popup-section section-agree">
              <UserAgreement
                  ref="usrAgrmntRef"
                  v-model:user-agreement="userAgreement"
                  @applyBtnEnable="applyBtnEnable"
              />
            </article>
          </div>
        </div>
        <SupportPopupButton
            :stt="data && data.stt"
            :aply-stt-cd-dcd="data && data.aplySttCdDcd"
            :apply-btn-enable="applyBtnEnable"
            :user-agreement="userAgreement"
            :apply="applyCheck"
            :cancel="cancel"
            :close-modal="closeModal"
        />
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>

  <SearchLicenseModal
      v-if="showSearchModal"
      v-model="showSearchModal"
      v-model:lrn-cost-div-sn="param.lrnCostDivSn"
      v-model:selected-value="license"
      @update:modelValue="changeFld"
  />

</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import Payment from '@/components/support/common/Payment';
import {
  applyBtnEnable,
  cancelSupport,
  checkUploadBinaries,
  getFileUploadFailResults,
  // getLrnCostFld,
  getLrnCostTy,
  paymentDomain,
  paymentInfoValidation,
  proofDivCdDcds,
  saveSupport,
  setCostProof,
  showComplete,
  uploadCostproof,
  extensions,
  SUPPORT_EVL_YEARLY_LIMIT,
} from '@/assets/js/modules/support/support-common';
import {closeToggles} from '@/assets/js/ui.init';
import Applicant from '@/components/support/common/Applicant';
import InputSelect from '@/components/support/common/InputSelect';
import InputSearch from '@/components/support/common/InputSearch';
import RowInputText from '@/components/support/common/RowInputText';
import {
  initParams,
  isSuccess,
  nullEmpty,
  numberOnlyChk,
  paramCheck,
  setParams,
  numberComma,
  getItem, lengthCheck
} from '@/assets/js/util';
import {
  ACT_DELETE_CANCEL_LRN_SPRT_EVL,
  ACT_INSERT_LRN_SPRT_EVL,
  ACT_UPDATE_LEARN_SUPPORT_EVALUATION,
  ACT_GET_EVL_SPRT_AMT_INFO,
  ACT_GET_EVL_LCSN_DUP_CHK
} from '@/store/modules/support/support';
import {useAlert} from '@/assets/js/modules/common/alert';
import RowDatepicker from '@/components/support/common/RowDatepicker';
import Upload from '@/components/support/common/Upload';
import SupportPopupButton from "@/components/support/common/SupportPopupButton";
import UserAgreement from "@/components/support/common/UserAgreement";
import SearchLicenseModal from '@/components/support/SearchLicenseModal';
import {useStore} from "vuex";
import {ACT_DIGITAL_REQUIREMENT_CHECK} from "@/store/modules/my/my";

export default {
  name: 'SupportEvalFeeModal',
  components: {SupportPopupButton, Upload, RowDatepicker, RowInputText, InputSelect, Applicant, Payment, UserAgreement, SearchLicenseModal, InputSearch},
  props: {
    modelValue: Boolean,
    data: { type: Object, default: null },
    reList : Function
  },
  setup(props, {emit}) {
    const {showMessage, showLoading, hideLoading, showConfirm} = useAlert();
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const userAgreement = ref(false), isInit = ref(false), isLoading = ref(false);
    const isReadOnly = computed(() => props.data && props.data.aplySttCdDcd === '2106006');
    const lrnCostDivs = ref([]); // , lrnCostFldLst = ref([])
    // const lrnCostFlds = computed(() => lrnCostFldLst.value.filter(x => x.lrnCostDivSn === param.lrnCostDivSn));
    const evlLocs = [{nm:'국내'}, {nm:'국외'}];
    const costAplies = [{nm:'신청', val:'Y'}, {nm:'신청 안함', val:'N'}];
    const gradePcce = [{nm:'1등급(LV.1)', val:'1'}, {nm:'2등급(LV.2)', val:'2'},{nm:'3등급(LV.3)', val:'3'},{nm:'4등급(LV.4)', val:'4'}];
    const gradePccp = [{nm:'1등급(LV.1)', val:'1'}, {nm:'2등급(LV.2)', val:'2'},{nm:'3등급(LV.3)', val:'3'},{nm:'4등급(LV.4)', val:'4'},{nm:'5등급(LV.5)', val:'5'}];
    const showSearchModal = ref(false);
    const supportEvlYearlyLimit = ref(SUPPORT_EVL_YEARLY_LIMIT);

    const gradeDT = [{nm:'DT-Gold (90점 이상)', val:'DT-Gold (90점 이상)'}, {nm:'DT-Blue(80점~89점)', val:'DT-Blue(80점~89점)'},{nm:'DT-Green(60점~79점)', val:'DT-Green(60점~79점)'}];

    const license = ref({
      lrnCostDivSn: null,
      lrnCostFldSn: null,
      lrnCostFldNm: null,
      lrnCostGrpCdDcd:null
    });

    const changeFld = () => {
      if(license.value != null) {
        param.lrnCostFldSn = license.value.lrnCostFldSn;
      }
    };

    const sprtAmtInfo = reactive({
      examFee: 0,
      sprtExptAmt: 0,
      sprtAmt: 0,
    })

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    // const totalSprtExptAmt = computed(() => {
    //   if (props.data != null) {
    //     return numberComma(Number(myTotalSprtExptAmt.value) - Number(props.data.sprtExptAmt) + param.sprtExptAmt);
    //   } else {
    //     return numberComma(Number(myTotalSprtExptAmt.value) + param.sprtExptAmt);
    //   }
    // });
    //
    // const myTotalSprtExptAmt = ref(0), myTotalSprtAmt = ref(0);

    const param = reactive({
      costEvlSn: 0,
      lrnCostDivSn: 0,
      lrnCostFldSn: 0,
      evlNm: '',
      evlExamDd: '',
      lcsnAcqsDd: '',
      evlGrade: '',
      evlScore:'',
      evlLoc: '',
      costAplyYn: '',
      examFee: '',
      sprtExptAmt: '',
      sprtAmt: '',
      rtnYn:'N',
      rtnAmt:0,
      addAmtYn:'N',
      addAmt:0,
      aprvrId: '',
      aprvrNm: '',
      aprvDt: '',
    });

    const toeic = reactive({
      evlLcScore:'',
      evlRcScore:'',
      evlSGrade:'',
      evlWGrade:'',
    })

    const paymentInfo = reactive({...paymentDomain});

    const lrnCertFiles = ref({ files: [], removed: [], binaries: [] }), // 성적증명서 || 합격증 사본
          receiptFiles = ref({ files: [], removed: [], binaries: [] }); // 결제 영수증

    const toggles = reactive({
      cardKinds: false,
      cardAprvDt: false,
      lrnCostDivs: false,
      // lrnCostFlds: false,
      evlExamDd: false,
      evlLocs: false,
      costAplies: false,
      lcsnAcqsDd: false,
    });

    const numChk = (e) => {
      if ( !numberOnlyChk( e.target.value) ) {
        showMessage('숫자입력만 가능합니다.');
        e.target.value = '';
        return false;
      }

    };

    const closeModal = () => {
      if (props.reList instanceof Function) props.reList();
      initLocalParams();
      emit('update:modelValue',false);
    };

    const applyYnSelect = ref(false);
    const applyYn = ref(true);
    const paymentType = ref('card');

    const handleSelectBox = (yn) => {
      applyYn.value = yn;
      applyYnSelect.value = false;
    };

    const usrAgrmntRef = ref(null);

    const initLocalParams = () => {
      initParams(param);
      initParams(paymentInfo);
      initParams(lrnCertFiles.value);
      initParams(receiptFiles.value);
      // closeModal();
      userAgreement.value = false;
      if(props.data == null) {
        usrAgrmntRef.value.allUnChk();
      }
      isLoading.value = false;
    };

    const searchModalFunc = () => {
      if (paramCheck(param.lrnCostDivSn)) {
        showMessage('평가 구분을 선택해주세요');
        return false;
      }
      showSearchModal.value=true;
    };

    const validate = () => {
      if (paramCheck(param.lrnCostDivSn)) {
        showMessage('평가 구분을 선택해주세요');
        return false;
      }
      if (paramCheck(param.lrnCostFldSn)) {
        showMessage('평가 분야를 선택해주세요');
        return false;
      }
      if (paramCheck(param.evlExamDd)) {
        showMessage('평가응시일을 선택해주세요');
        return false;
      }


      // if (paramCheck(param.evlLoc)) {
      //   showMessage('평가장소를 입력해주세요');
      //   return false;
      // }
      // 평가구분 == 외국어 or ICT
      if (param.lrnCostDivSn === 1 || param.lrnCostDivSn === 2) {

        if (paramCheck(param.evlGrade) && paramCheck(param.evlScore)) {
          showMessage('성적을 입력해주세요');
          return false;
        }
        if (paramCheck(param.costAplyYn)) {
          showMessage('비용신청여부를 선택해주세요');
          return false;
        }
        // 성적사본 업로드
      } else if (param.lrnCostDivSn === 4) { // 평가구분 == DITY 인것만
        if (paramCheck(param.lcsnAcqsDd)) {
          showMessage('자격증취득일을 입력해주세요');
          return false;
        }
      }


      if (param.costAplyYn === 'Y') {

        if(supportEvlYearlyLimit.value-(sprtAmtInfo.sprtAmt+sprtAmtInfo.sprtExptAmt) <= 0){
          showMessage('이미 지원 한도액이 소진되어<br>비용지원이 불가능합니다.');
          param.costAplyYn = 'N';
          return false;
        }

        // 응시료
        if (paramCheck(param.examFee)) {
          showMessage('응시료를 입력해주세요');
          return false;
        }

        let result = paymentInfoValidation(paymentInfo);
        if (!result.isValid) {
          showMessage(result.txt);
          return result.isValid;
        }
      }
      // 결제 영수증
      return true;
    };

    const result = reactive({
      alreadyUse: '',
      useYn: '',
    });

    const applyCheck = async () => {
      // console.log("ajsiodsa", param.lrnCostDivSn); // IT는 4
      // console.log("ajsiodsa", param.evlGrade); //
      let confirmTxt = `해당 신청 건은<br> 직무 필수과정으로 인정되지 않습니다.`;
      // let applyRes = null;

      if(param.lrnCostFldSn == '268' || param.lrnCostFldSn == '269') { // 268 : PCCP / 269 : PCCE
        await store.dispatch(`my/${ACT_DIGITAL_REQUIREMENT_CHECK}`).then(res => {
          if (lengthCheck(res)) {
            setParams(result, getItem(res))
          }
          // menuView.value = 'overallResult';
        }).catch(e => {
          console.error(e);
        })
        console.log(result);
        if (result.alreadyUse == '0' && result.useYn == 'Y' && (param.lrnCostFldSn == '268' || param.evlGrade != 1)) {
          confirmTxt = `해당 신청 건은<br> 직무 필수과정으로 인정됩니다.`;
        }

        showConfirm({
          title: '직무 필수과정 안내',
          text: confirmTxt,
          callback: () => {
            apply();
          }
        })
      } else {
        apply();
      }
    };

    const apply = async () => {
      nullEmpty(param);
      nullEmpty(paymentInfo);
      if (param.lcsnAcqsDd && param.lcsnAcqsDd.indexOf('-') > -1) {
        param.lcsnAcqsDd = param.lcsnAcqsDd.replaceAll('-', '');
      }
      showLoading();
      let applyRes = null, lrnCertRes=null, receRes=null;
      let hasLrnCertFile = checkUploadBinaries(lrnCertFiles), hasReceiptFile = checkUploadBinaries(receiptFiles);

      // if (!paramCheck(toeic.evlLcScore) && !paramCheck(toeic.evlRcScore)){

      if(param.lrnCostFldSn == '106'){// 토익 LC/RC점수
        param.evlGrade = toeic.evlLcScore+'_'+toeic.evlRcScore;
        param.evlScore = parseInt(toeic.evlLcScore)+parseInt(toeic.evlRcScore);
      }else if(param.lrnCostFldSn == '243') {// 토익 S+W
        param.evlGrade = toeic.evlLcScore+'_'+toeic.evlRcScore+'|'+toeic.evlSGrade+'_'+toeic.evlWGrade;
        param.evlScore = parseInt(toeic.evlLcScore)+parseInt(toeic.evlRcScore);
      }
      // console.log(param.evlGrade + ' / '+param.evlScore);

      if (param.costEvlSn > 0) {
        applyRes = await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_EVALUATION}`, {
          costEvlSn: param.costEvlSn,
          params: { ...param, ...paymentInfo,
            removedFiles: lrnCertFiles.value.removed.concat(receiptFiles.value.removed)
          }}, userAgreement, validate);
      } else {
        applyRes = await saveSupport(isLoading, `support/${ACT_INSERT_LRN_SPRT_EVL}`, {...param, ...paymentInfo}, userAgreement, validate);
        if (applyRes) {
          param.costEvlSn = applyRes.costEvlSn;
        }
      }

      if (param.costEvlSn > 0) {
        if (hasLrnCertFile) {
          lrnCertRes = await uploadCostproof({
            params: {costEvlSn: param.costEvlSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_EVL_TRANSCRIPT},
            files: lrnCertFiles.value.files
          });
        }
        if(hasReceiptFile) {
          receRes = await uploadCostproof({
            params: {costEvlSn: param.costEvlSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_EVL_RECEIPT},
            files: receiptFiles.value.files
          });
        }
      }

      // let failStr = getFileUploadFailResults({title: '성적증명서 또는 합격증 사본', res: lrnCertRes}, {title: '결제 영수증', res: receRes});

      let failed = getFileUploadFailResults(lrnCertRes, receRes);

      if (isSuccess(applyRes)) {
        showComplete(isLoading, applyRes, failed, ()=>{
          closeModal();
          // initLocalParams();
        });

      } // 위에 validate 함수가 있어서 else 조건 넣으면 안됨.
      hideLoading();
    };

    const updatePaymentInfo = (emitted) => {
      setParams(paymentInfo, emitted);
    };

    const tmpYear = ref('');

    watch(() => param.evlExamDd, () => {
      if (!paramCheck(param.evlExamDd)) {
        let strYear = param.evlExamDd.substring(0, 4);

        if (tmpYear.value == '' || tmpYear.value != strYear) {

          store.dispatch(`support/${ACT_GET_EVL_SPRT_AMT_INFO}`, strYear).then(res => {
            if (res.status && res.status.code == 200) {

              sprtAmtInfo.sprtAmt = getItem(res).sprtAmt;
              sprtAmtInfo.sprtExptAmt = getItem(res).sprtExptAmt;
              sprtAmtInfo.examFee = getItem(res).examFee;

            } else {
              sprtAmtInfo.examFee = 0;
              sprtAmtInfo.sprtExptAmt = 0;
              sprtAmtInfo.sprtAmt = 0;
            }
          });

        }
      }
    });

    watch(() => param.costAplyYn, () => {
      if(param.costAplyYn == "Y"){
        if (paramCheck(param.evlExamDd)) {
          showMessage('평가응시일을 선택해주세요');
          param.costAplyYn = "";
        }

        if(supportEvlYearlyLimit.value <= (sprtAmtInfo.sprtAmt+sprtAmtInfo.sprtExptAmt)){
          showMessage('이미 지원 한도액이 소진되어<br>비용지원이 불가능합니다.');
          param.costAplyYn = "N";
        }
        // else{
        //   if(supportEvlYearlyLimit.value-(sprtAmtInfo.sprtAmt+sprtAmtInfo.sprtExptAmt) < param.examFee){
        //     showMessage('지원가능한 한도금액이 초과되어<br>일부 금액만 지원이 가능합니다.');
        //   }
        // }

      }
    });

    watch(() => param.examFee, () => {
      param.sprtExptAmt = param.examFee;
    });

    watch(() => param.lrnCostDivSn, () =>{
      if (session.value.deptCd ==='사무직원' &&(param.lrnCostDivSn !== 1 && param.lrnCostDivSn !== null)){
        showMessage("해당 구분 신청 대상자가 아닙니다.");
        param.lrnCostDivSn = null;
        return;
      }

      if(param.lrnCostDivSn != license.value.lrnCostDivSn && param.lrnCostDivSn != null) {
        license.value.lrnCostDivSn = param.lrnCostDivSn;
        license.value.lrnCostFldNm = null;
        param.lrnCostFldSn = null;
        license.value.lrnCostFldSn = null;
        license.value.lrnCostGrpCdDcd = null;
      }
    });

    watch( () => param.lrnCostFldSn, () => {
      if (param.lrnCostDivSn == 4 && param.lrnCostFldSn != null && license.value.lrnCostGrpCdDcd != '2110101' && props.data == null){
        // console.log(props.data.stt);

        store.dispatch(`support/${ACT_GET_EVL_LCSN_DUP_CHK}`, param.lrnCostFldSn).then(res => {
          if (res.status && res.status.code == 200) {
            // console.log('evlLcsnDupChk : ' + res.evlLcsnDupChk);
            if (res.evlLcsnDupChk != 'OK'){
              showMessage('이미 해당 건으로 신청중이거나 등재된 자격증입니다.');
              license.value.lrnCostFldSn = null;
              license.value.lrnCostFldNm = null;
              license.value.lrnCostGrpCdDcd = null;
              param.lrnCostFldSn = null;
              return;
            }
          } else {
            showMessage('신청중복체크중 오류');
            return false;
          }
        });
      }
    });

    getLrnCostTy({evlUseYn: 'Y'}, lrnCostDivs);
    // getLrnCostFld({evlUseYn: 'Y'}, lrnCostFldLst);

    // getMyEvalTotal().then(res => {
    //   if (res) {
    //     if (res.totalSprtExptAmt > 0) myTotalSprtExptAmt.value = res.totalSprtExptAmt;
    //     if (res.totalSprtAmt > 0) myTotalSprtAmt.value = res.totalSprtAmt;
    //   }
    // });

    const initToeic = () => {
      toeic.evlLcScore = '';
      toeic.evlRcScore = '';
      toeic.evlSGrade = '';
      toeic.evlWGrade = '';
    };

    onMounted(() => {
      if (props.data != null) {
        setParams(license.value, props.data);
        setParams(param, props.data);

        initToeic();
        if(param.lrnCostFldSn == '106'){// 토익 LC/RC점수
          if(param.evlGrade.indexOf('_') > 0) {
            toeic.evlLcScore = param.evlGrade.substring(0, param.evlGrade.indexOf('_'));
            toeic.evlRcScore = param.evlGrade.substring(param.evlGrade.indexOf('_') + 1);
          }else{
            toeic.evlLcScore = param.evlGrade;
          }
        }else if(param.lrnCostFldSn == '243'){// 토익 S+W
          let scr = param.evlGrade.substring(0, param.evlGrade.indexOf('|'));
          let grd = param.evlGrade.substring(param.evlGrade.indexOf('|') + 1);

          toeic.evlLcScore = scr.substring(0, scr.indexOf('_'));
          toeic.evlRcScore = scr.substring(scr.indexOf('_')+1);
          toeic.evlSGrade = grd.substring(0, grd.indexOf('_'));
          toeic.evlWGrade = grd.substring(grd.indexOf('_')+1);

          // console.log('scr : '+scr+' / grd : '+grd);
          // console.log('evlLcScore : '+toeic.evlLcScore+' / evlRcScore : '+toeic.evlRcScore+' / evlSGrade : '+toeic.evlSGrade+' / evlWGrade : '+toeic.evlWGrade);
        }

        // console.log('rtnYn:'+param.rtnYn+'/addAmtYn:'+param.addAmtYn);
        setCostProof(props.data.proofs, lrnCertFiles, proofDivCdDcds.PROOF_DIV_CD_EVL_TRANSCRIPT);
        setCostProof(props.data.proofs, receiptFiles, proofDivCdDcds.PROOF_DIV_CD_EVL_RECEIPT);
        setParams(paymentInfo, props.data);

        userAgreement.value = true;
      }
      isInit.value = true;
    });

    const cancel = () => {
      cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_EVL, param.costEvlSn,'평가비 지원', () => {
        closeModal();
      });
    }

    return {
      isInit, isReadOnly, lrnCostDivs, evlLocs, costAplies, gradePcce, gradePccp,// , lrnCostFlds
      param, paymentInfo, toggles, lrnCertFiles, receiptFiles,gradeDT,
      applyYn, applyYnSelect, paymentType, userAgreement,
      handleSelectBox, applyBtnEnable, apply, applyCheck, closeToggles, closeModal,
      updatePaymentInfo, cancel, toeic, numChk, extensions, usrAgrmntRef,
      searchModalFunc, showSearchModal, license, changeFld, sprtAmtInfo, supportEvlYearlyLimit,   numberComma,
    }
  }
};
</script>