import {
  ACT_GET_JM_JOB_CERT_LIST, ACT_GET_VICE_PROMOTION_REQUIREMENT_LIST,
  ACT_INSERT_LEARN_PROGRESS_LOGS,
  ACT_INSERT_LEARN_PROGRESS_RESULTS,
} from '@/store/modules/learn/learn';
import store from '@/store';
import {dateToDatePart, getItems, lengthCheck, timestampToDatePart} from '@/assets/js/util';
import {holyDay} from '@/assets/js/holy-day';
import {useAlert} from '@/assets/js/modules/common/alert';

export const lrnObjTyCds = {
  MP4	: '100001', // 진도율
  MP3	: '100002',  // 진도율
  PDF	: '100003',  // 진도율
  Youtube	: '100004',  // 진도율

  Evaluation	: '100005', // 평가
  Survey	: '100006',
  Collective	: '100007',
  CloudNow	: '100008', // 비대면집합(NOW) -- 클라우드 나우(모두 노출)
  CloudNowLive	: '100026', // 라이브특강(NOW) -- 러닝 라이브(국민은행만 노출)

  Report	: '100009',

  Scorm : '100025',

  Board	: '100010',
  LearnBoard	: '100011',

  Certificate	: '100012',

  HTML	: '100013', // 진도율

  RefBoard	: '100014',
  RefEvaluation	: '100015',
  RefFinish	: '100016',

  Custom	: '100017',
  Book	: '100018',
  Tool	: '100019',  // 진도율
  CloudOn	: '100020',

  SelectionSurvey: '100022',
  LearningTime :'100031', //러닝타임
}

export const fnshCateCdDcds = {
  Progress: '2046001',
  Attendance: '2046002',
  Evaluation: '2046003',
  Report : '2046004'
}

export const fnshCrtrCdDcds = {
  Normal: '2045001',
  Special: '2045002'
}

export const fnshCondCdDcds = {
  Percentage	: '2044001',
  Things	: '2044002',
  Score	: '2044003',
  Episodes	: '2044004',
  Receipt	: '2044005',
  Write	: '2044006',
  Not	: '2044007'
}

export const getFinishSymbolType = (item) => {
  if(item.fnshCrtrCdDcd === fnshCrtrCdDcds.Normal){
    if(item.fnshCateCdDcd === fnshCateCdDcds.Progress){
      return 'symbol-type-contents';
    }else if(item.fnshCateCdDcd === fnshCateCdDcds.Evaluation){
      return 'symbol-type-evaluation';
    }else if(item.fnshCateCdDcd === fnshCateCdDcds.Attendance){
      return 'symbol-type-gathering';
    }else if(item.fnshCateCdDcd === fnshCateCdDcds.Report){
      return 'symbol-type-report';
    }
  }else if(item.fnshCrtrCdDcd === fnshCrtrCdDcds.Special){
    return 'symbol-type-social';
  }
  return null;
}

export const cmsTypes = [lrnObjTyCds.MP4, lrnObjTyCds.MP3, lrnObjTyCds.PDF, lrnObjTyCds.Youtube, lrnObjTyCds.HTML, lrnObjTyCds.Tool,lrnObjTyCds.Scorm];
export const collectiveTypes = [lrnObjTyCds.Collective, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn, lrnObjTyCds.CloudNowLive];
export const cloudNowTypes = [lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn, lrnObjTyCds.CloudNowLive];

export const getTutors = (item, defaultValue) => {
  // if(collectiveTypes.includes(item.lrnObjTyCd)) {
    if(item.instrs && item.instrs.length > 0){
      if(item.instrs.length === 1){
        return item.instrs[0].instrNm;
      }else{
        return `${item.instrs[0].instrNm} 외 ${item.instrs.length - 1}명`;
      }
    } else {
      return defaultValue ? defaultValue : '';
    }
  // }
}

const progressTypes = [lrnObjTyCds.MP4, lrnObjTyCds.MP3, lrnObjTyCds.PDF, lrnObjTyCds.Youtube, lrnObjTyCds.HTML, lrnObjTyCds.Tool,lrnObjTyCds.Scorm];
// const collectiveTypes = [lrnObjTyCds.Collective, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn];
const reportTypes = [lrnObjTyCds.Report];
const evaluationTypes = [lrnObjTyCds.Evaluation];
const surveyTypes = [lrnObjTyCds.Survey];
// const boardTypes = [lrnObjTyCds.Board, lrnObjTyCds.LearnBoard];

export const isProgress = (lrnObjTyCd) => {
  return progressTypes.includes(lrnObjTyCd);
}

export const isEvaluation = (lrnObjTyCd) => {
  return evaluationTypes.includes(lrnObjTyCd);
}

export const isSurvey = (lrnObjTyCd) => {
  return surveyTypes.includes(lrnObjTyCd);
}

export const isReport = (lrnObjTyCd) => {
  return reportTypes.includes(lrnObjTyCd);
}

// export const isScorm = (lrnObjTyCd) => {
//   return scormTypes.includes(lrnObjTyCd);
// }

const referenceLearnObjectTypes = [
  lrnObjTyCds.Certificate,
  lrnObjTyCds.RefBoard,
  lrnObjTyCds.RefEvaluation,
  lrnObjTyCds.RefFinish,
  lrnObjTyCds.Custom,
  lrnObjTyCds.Book
];

export const isReferenceLearn = (lrnObjTyCd) => {
  return referenceLearnObjectTypes.includes(lrnObjTyCd);
}

export const insertLearnProgressResult = (params, callback) => {
  store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS_RESULTS}`, params).then(callback);
}

export const insertLearnProgressLog = (params, callback) => {
  store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS_LOGS}`, params).then(res => {
    if(callback instanceof Function) callback(res);
  });
}

export const canDo = (item, currentDate) => {
  // if(evaluationTypes.includes(item.lrnObjTyCd)){
  //   return item.evlBgngDt <= currentDate.getTime() && item.evlEndDt > currentDate.getTime();
  // }else if(surveyTypes.includes(item.lrnObjTyCd)){
  //   return item.svyBgngDt <= currentDate.getTime() && item.svyEndDt > currentDate.getTime();
  // }else{
  // 클라우드 NOW의 경우에는 distSvySn이 존재하지 않을수도 있고 EndDt가 현재보다 클 경우에 노출
  if (cloudNowTypes.includes(item.lrnObjTyCd)) {
    return item.objEndDt >= currentDate.getTime();
  } else if (collectiveTypes.includes(item.lrnObjTyCd)) {
    // 학습홈 > 집합 객체의 경우 해당 객체에 설문이 달려 있을 경우 객체가 종료되었을 때 설문이 오픈된다.
    return item.distSvySn > 0 && item.objEndDt <= currentDate.getTime();
  } else {
    if(!item.objBgngDt && !item.objEndDt) {
      // 학습 객체의 시간이 없다면 차수의 학습기간을 기준으로 처리
      return item.bgngDt <= currentDate.getTime() && item.endDt > currentDate.getTime();
    }
    return item.objBgngDt <= currentDate.getTime() && item.objEndDt > currentDate.getTime();
  }



  // }
}

export const isTodayLearn = (item, currentDate) => {
  // const currentTs = currentDate.getTime();
  const currentYmd = dateToDatePart(currentDate).ymd;
  // if(evaluationTypes.includes(item.lrnObjTyCd)){
  //   // return item.evlBgngDt <= currentTs && item.evlEndDt > currentTs;
  //   return timestampToDatePart(item.evlBgngDt).ymd <= currentYmd && timestampToDatePart(item.evlEndDt).ymd >= currentYmd;
  // }else if(surveyTypes.includes(item.lrnObjTyCd)){
  //   // return item.svyBgngDt <= currentTs && item.svyEndDt > currentTs;
  //   return timestampToDatePart(item.svyBgngDt).ymd <= currentYmd && timestampToDatePart(item.svyEndDt).ymd >= currentYmd;
  // }
  return timestampToDatePart(item.objBgngDt).ymd <= currentYmd && timestampToDatePart(item.objEndDt).ymd >= currentYmd;

  // return item.objBgngDt <= currentTs &&item.objEndDt > currentTs;
  // return item.objBgngDt > 0 && item.objEndDt > 0 && dateToDatePart(item.objBgngDt).ymd <= currentYmd && dateToDatePart(item.objEndDt).ymd >= currentYmd;
}

export const isTodayScheduled = (item, currentDate) => {
  if(currentDate){
    const currentYmd = dateToDatePart(currentDate).ymd;
    // if(evaluationTypes.includes(item.lrnObjTyCd)){
    //   return currentYmd === timestampToDatePart(item.evlBgngDt).ymd && currentYmd === timestampToDatePart(item.evlEndDt).ymd;
    // }else if(surveyTypes.includes(item.lrnObjTyCd)){
    //   return currentYmd === timestampToDatePart(item.svyBgngDt).ymd && currentYmd === timestampToDatePart(item.svyEndDt).ymd;
    // }
    return currentYmd === timestampToDatePart(item.objBgngDt).ymd && currentYmd === timestampToDatePart(item.objEndDt).ymd;
  }
  return false;
}

const { showMessage } = useAlert();

export const enableLearn = (lrnPsbltHrDiv, isShow) => {
  if (lrnPsbltHrDiv !== '02' && lrnPsbltHrDiv !== '03') return true;
  const currentPart = dateToDatePart(new Date());

  // const currentPart2 = dateToDatePart(new Date());
  // const currentPart = {
  //   w: 1,
  //   hour: `0${8 + (count > 2 ? 1 : 0)}`,
  //   min: '50',
  //   ymd: '20221223'
  // }

  const hhmm = `${currentPart.hour}${currentPart.min}`;
  if (lrnPsbltHrDiv === '02') {
    const enable =
        currentPart.w >= 1 && currentPart.w <= 5
        && !holyDay[currentPart.ymd]
        && hhmm >= '0900' && hhmm < '1800';
    // if (!enable && isShow) showMessage('근무시간내에서만 학습가능합니다.');
    if (!enable && isShow) showMessage('근무시간내(09:00~18:00) 학습가능');
    return enable;
  } else if (lrnPsbltHrDiv === '03') {
    const enable =
        currentPart.w === 0 || currentPart.w === 6 || holyDay[currentPart.ymd] || hhmm < '0830' || hhmm >= '1900'
    if (!enable && isShow) showMessage('근무시간외(19:00~08:30) 학습가능');

    return enable;
  }

  return false;

}

export const getJmJobCertList = (item) => {
  store.dispatch(`learn/${ACT_GET_JM_JOB_CERT_LIST}`).then(res => {
    if(lengthCheck(res)) {
      item.value = getItems(res).map(x => ({
        ...x,
        //인증분야
        badge1: ['개인금융'].includes(x.j1Memo) ? 'badge-primary' : 'badge-silver',
        badgeText1: ['개인금융'].includes(x.j1Memo) ? '인증' : '미인증',
        viceBadge1 : x.j1Flag  === 'Y' ?  'badge-primary' : 'badge-silver',
        viceText1 : x.j1Flag  === 'Y' ?  '충족' : '미충족',

        // 부점장 승진 자격요건
        badge2: ['기업금융'].includes(x.j2Memo)  ? 'badge-primary' : 'badge-silver',
        badgeText2: ['기업금융'].includes(x.j2Memo) ? '인증' : '미인증',
        viceBadge2 :x.j2Flag === 'Y' ? 'badge-primary' : 'badge-silver',
        viceText2 : x.j2Flag  === 'Y' ?  '충족' : '미충족',
      }))[0]
    } else {
      item.value = {
        //인증분야
        badge1: 'badge-silver',
        badgeText1: '미인증',
        viceText1 : '미충족',
        viceBadge1 : 'badge-silver',
        j1Memo: '-',

        // 부점장 승진 자격요건
        badge2: 'badge-silver',
        badgeText2: '미인증',
        viceText2 : '미충족',
        viceBadge2 :'badge-silver',
        j2Memo: '-'
      };
    }
  });
};

export const individualAllCds = [
  {cd : 'J211', type: '개인금융', name: '직무인증 개인금융'},
  {cd : 'JM30', type: '자산관리컨설팅', name: '직무인증 Level I (자산관리컨설팅_특례인정)'},
  {cd : 'JM31', type: '자산관리컨설팅', name: '직무인증 Level I (자산관리컨설팅)'},
  {cd : 'JM32', type: '자산관리컨설팅', name: '직무인증 Level II (자산관리컨설팅)'},
]

export const enterpriseAllCds = [
  {cd : 'J221', type: '기업금융', name: '직무인증 기업금융'},
  {cd : 'J220', type: '기업금융', name: '직무인증 기업금융(특례인정)'},
  {cd : 'JM1A', type: '외환', name: '직무인증 Level I (외환_주말대학)'},
  {cd : 'JM10', type: '외환', name: '직무인증 Level I (외환_특례인정)'},
  {cd : 'JM11', type: '외환', name: '직무인증 Level I (외환)'},
  {cd : 'JM12', type: '외환', name: '직무인증 Level II (외환)'},
  {cd : 'JM20', type: '기업여신', name: '직무인증 Level I (기업여신_특례인정)'},
  {cd : 'JM21', type: '기업여신', name: '직무인증 Level I (기업여신)'},
  {cd : 'JM22', type: '기업여신', name: '직무인증 Level II (기업여신)'},
  {cd : 'JM70', type: '심사/신용평가', name: '직무인증 Level I (심사/신용평가_특례인정)'},
  {cd : 'JM71', type: '심사/신용평가', name: '직무인증 Level I (심사/신용평가)'},
  {cd : 'JM72', type: '심사/신용평가', name: '직무인증 Level II (심사/신용평가)'},
  {cd : 'JM81', type: '여신사후관리', name: '직무인증 Level I (여신사후관리)'},
  {cd : 'JM8A', type: '여신사후관리', name: '직무인증 Level I (여신사후관리실무_주말대학)'},
  {cd : 'JM80', type: '여신사후관리', name: '직무인증 Level I (여신사후관리_특례인정)'},
  {cd : 'JM82', type: '여신사후관리', name: '직무인증 Level II (여신사후관리)'},
  {cd : 'JM92', type: '기업여신/심사신용평가', name: '직무인증 Level II (기업여신/심사신용평가)'},

  // {cd : 'JM40', type: 'IB/국제금융', name: '직무인증 Level I (IB/국제금융_특례인정)'},
  // {cd : 'JM41', type: 'IB/국제금융', name: '직무인증 Level I (IB/국제금융)'},
  // {cd : 'JM42', type: 'IB/국제금융', name: '직무인증 Level II (IB 국제금융)'},
  // {cd : 'JM50', type: '트레이딩/파생상품', name: '직무인증 Level I (트레이딩/파생상품_특례인정)'},
  // {cd : 'JM51', type: '트레이딩/파생상품', name: '직무인증 Level I (트레이딩/파생상품)'},
  // {cd : 'JM52', type: '트레이딩/파생상품', name: '직무인증 Level II (트레이딩/파생상품)'},
  // {cd : 'JM60', type: '리스크관리', name: '직무인증 Level I (리스크관리_특례인정)'},
  // {cd : 'JM61', type: '리스크관리', name: '직무인증 Level I (리스크관리)'},
  // {cd : 'JM62', type: '리스크관리', name: '직무인증 Level II (리스크관리)'},
]

export const individualCds = individualAllCds.map(x => x.cd);
export const enterpriseCds = enterpriseAllCds.map(x => x.cd);

export const getVicePromotionRequirementList = (items) => {
  store.dispatch(`learn/${ACT_GET_VICE_PROMOTION_REQUIREMENT_LIST}`).then(res => {
    if(lengthCheck(res)) {
      items.value = getItems(res);
    }
  });
};