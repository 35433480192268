import ApiService from "@/assets/js/api.service";


export const ACT_GET_DUTY_LRN_STATUS_LIST = 'actGetDutyLrnStatusList';
export const ACT_GET_DEPT_REQ_TRAIN_APPLY_LIST = 'actGetDeptReqTrainApplyList';
export const ACT_GET_DEPT_REQ_TRAIN_APPLY = 'actGetDeptReqTrainApply';
export const ACT_INSERT_DEPT_REQ_TRAIN_MSTS = "actInsertDeptReqTranMsts"
export const ACT_INSERT_DEPT_REQ_TRAIN_ATCH_FILES = 'actInsertDeptReqTrainAtchFiles';
export const ACT_INSERT_DEPT_REQ_TRAIN_DISTS = 'actInsertDeptReqTrainDists';
export const ACT_DELETE_DEPT_REQ_TRAIN_DISTS = 'actDeleteDeptReqTrainDists';
export const ACT_DOWNLOAD_DEPT_REQ_TRAIN_ATCH = 'actDwonloadDeptReqTrainAtch';

const state = {
};

const mutations = {
};

const actions = {
    [ACT_GET_DUTY_LRN_STATUS_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/dept/duty-lrn-stt', params).then(response => resolve(response))
        });
    },
    [ACT_GET_DEPT_REQ_TRAIN_APPLY_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/depts/reqs/trains/list', params).then(response => resolve(response))});
    },
    [ACT_GET_DEPT_REQ_TRAIN_APPLY](context, deptReqSn){
        return new Promise(resolve => {
            ApiService.query(`/v1/app/depts/reqs/trains/${deptReqSn}`, {}).then(response => resolve(response));
        });
    },

    [ACT_INSERT_DEPT_REQ_TRAIN_MSTS](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/depts/reqs/trains/msts', params).then(response => resolve(response));
        });
    },
    [ACT_INSERT_DEPT_REQ_TRAIN_ATCH_FILES](context, {params, files}) {
        return new Promise(resolve => {
            ApiService.upload('/v1/app/depts/reqs/atchFiles', files, params, true).then(response => resolve(response))});
    },
    [ACT_INSERT_DEPT_REQ_TRAIN_DISTS](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/depts/reqs/trains/dists', params).then(response => resolve(response));
        });
    },
    [ACT_DELETE_DEPT_REQ_TRAIN_DISTS](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/depts/reqs/trains/dist/del', params).then(response => resolve(response));
        });
    },
    [ACT_DOWNLOAD_DEPT_REQ_TRAIN_ATCH](context, {deptReqFileAtchSn,fileName}){
        ApiService.download(`/v1/app/depts/reqs/trains/files/atchs`, deptReqFileAtchSn, null, fileName);
    },


};



export default {
    namespaced: true,
    state,
    mutations,
    actions
};

