import ApiService from '@/assets/js/api.service';
import {
    ACT_CANCEL_SSL,
    ACT_DELETE_SOC_BOARD,
    ACT_DELETE_SOC_CMNT,
    ACT_DOWNLOAD_SOC_ATCH,
    ACT_ENROLL_SSL,
    ACT_GET_BADGE_LIST,
    ACT_GET_GROUP_LRN_E_MNG,
    ACT_GET_LEARN_TARGET,
    ACT_GET_LEARN_TARGET_LIST,
    ACT_GET_MY_BJUGBNM,
    ACT_GET_MY_LEARN_TARGET_LIST,
    ACT_GET_MY_SOC_BOARD_COUNT,
    ACT_GET_MY_SOC_BOARD_LIST,
    ACT_GET_TOTAL_MY_SOC_BOARD_LIST,
    ACT_GET_MY_SOC_STAT,
    ACT_GET_REL_LIST,
    ACT_GET_SOC_ACT_BOARD_LIST,
    ACT_GET_SOC_ACT_LIST,
    ACT_GET_SOC_ACT_STAT_LIST,
    ACT_GET_SOC_ACTS_ATTEND_LIST,
    ACT_GET_SOC_ACTS_EXCELLENT_LIST,
    ACT_GET_SOC_BOARD_LIST,
    ACT_GET_SOC_BOARD_GROUP_LIST,
    ACT_GET_SOC_BOARD_WRITE_STAT_LIST,
    ACT_GET_SOC_BOARD_WRITE_STAT_VICE_LIST,
    ACT_GET_SOC_BOOK_LIST,
    ACT_GET_SOC_CMNT_LIST,
    ACT_GET_SOC_CNT,
    ACT_GET_SOC_EXCELLENT_BOARD_LIST,
    ACT_GET_SOC_SUBSCRIBE_BOARD_LIST,
    ACT_GET_SOC_MY_REL,
    ACT_GET_SOC_NTC,
    ACT_GET_SOC_NTC_LIST,
    ACT_GET_SOC_PAGE_LIST,
    ACT_GET_SOC_REF_LIST,
    ACT_GET_SOC_REL_GROUP_LIST,
    ACT_GET_SOC_REL_GROUP_TARGET,
    ACT_GET_SOC_REL_HRD_WRITE,
    ACT_GET_SOC_REL_STAT,
    ACT_GET_SOC_REL_WRITE,
    ACT_GET_SOC_REL_WRITE_LIST,
    ACT_GET_SOC_UV_LIST,
    ACT_GET_SSL_APPLY_CNT,
    ACT_GET_SSL_NTC,
    ACT_GET_SSL_NTC_LIST,
    ACT_GET_UNSPLASH_IMAGE_LIST,
    ACT_INSERT_SOC_ACT,
    ACT_INSERT_SOC_BOARD,
    ACT_INSERT_SOC_CMNT,
    ACT_SHARE_SOC_BOARD,
    ACT_UPDATE_SOC_BOARD,
    ACT_UPDATE_SOC_BOARD_INQ_CNT,
    ACT_UPDATE_SOC_CMNT,
    ACT_UPDATE_SOC_NTC_INQ_CNT,
    ACT_UPLOAD_SOC_BOARD_THUMBNAIL,
    ACT_GET_ACT_FEED_LIST,
    MUT_ADD_SUBSCRIBE_MY,
    MUT_CLOSE_ATTENDANCE,
    MUT_CLOSE_BADGE,
    MUT_CLOSE_LRNERS,
    MUT_INIT_MY_BADGE,
    MUT_INIT_MY_REL,
    MUT_REMOVE_STATICS,
    MUT_REMOVE_SUBSCRIBE_MY,
    MUT_SET_ENROLL_CNT,
    MUT_SET_EXCELLENT_LRNERS,
    MUT_SET_GROUP_LIST,
    MUT_SET_LEARN_TARGET_LIST,
    MUT_SET_LRNER_KEYWORD,
    MUT_SET_MY_ATTENDANCE,
    MUT_SET_MY_ATTENDANCE_LIST,
    MUT_SET_MY_BADGE,
    MUT_SET_MY_LEARN_TARGET_LIST,
    MUT_SET_MY_REL,
    MUT_SET_SSL_ACL,
    MUT_SET_STATICS,
    MUT_SET_SUBSCRIBE,
    MUT_SHOW_ATTENDANCE,
    MUT_SHOW_BADGE,
    MUT_TOGGLE_LRNERS,
    MUT_SET_POSTSN_LIST,
    MUT_SET_ROUTE_FROM,
    MUT_INIT_ROUTE_FROM, ACT_GET_SHARE_LIST,
    ACT_UPDATE_BOOK_MAPNG,
    ACT_GET_MY_SSL_LST,
    ACT_GET_TOTAL_MY_SOC_PUT_BOARD_LIST
} from './ssl-index';

// import {dateToDatePart} from '@/assets/js/util';

const state = {
    sslAcl: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') ,
    group: {
        distCrseSn: 0,
        crseNm: null,
        distCrseLrnFldSn: 0,
        distCrseLrnFldNm: null,
        leaderLrnerId: null
    },
    knowledge: {distCrseSn: 0},
    hrd: {distCrseSn: 0},
    // rel...

    calendar: {
        show: false,
        myAttends: []
    },

    // 구독 관련 정보
    subscribe: {
        my: [],
        target: [],
        expiredTime: 0
    },
    // 나의 글을 담은거 + 좋아요
    statistics: {
        like: 0,
        save: 0,
        expiredTime: 0
    },

    //ViewPage 이전글, 현재글, 다음글번호
    socBoardTotList : {
        postSns:[],
        socPaging : {
            pageNo: 0,
            pageSize: 0,
            totalCount: 0,
            hasMore: false
        },
        opt : {
            div:'my',
            title: '',
            sortC: ''
        }
    },

    // 나의 입과된 목록
    myLearnTargets: [],
    // 해당 차수(학습 그룹)에 입과된 직원들
    learnTargets: [],
    // 그룹러닝의 그룹들...
    groups: [],

    excellents: [],
    lrnerKeyword: '',

    //SSL 과정별 신청인원
    selfAppCnt:0,
    selfAppJaCnt:0,
    selfAppGiCnt:0,
    selfAppGaCnt:0,
    selfAppIctCnt:0,
    gropAppCnt:0,
    jisikeAppCnt:0,
    cloudAppCnt:0,
    bookAppCnt:0,
    totAppNonDupCnt:0,


    // badge
    badgeShow: false,
    badges: [],
    myBadges: [],

    // 글쓰기 전역 버튼 진입 페이지 정보
    routeFrom: {
        fullPath: '',
        name: '',
        query: {},
        params: {}
    },

};

const mutations = {
    [MUT_SET_SSL_ACL](state) {
        state.sslAcl = true;
    },
    [MUT_SET_SUBSCRIBE](state, {my, target}) {
        state.subscribe.my = my;
        state.subscribe.target = target;
        // 5분?
        state.subscribe.expiredTime = (new Date().getTime() + (5 * 60 * 1000));
    },
    [MUT_ADD_SUBSCRIBE_MY](state, my) {
        state.subscribe.my.push(my);
    },
    [MUT_REMOVE_SUBSCRIBE_MY](state, {actTyCdDcd, trgtLrnerId, lrnTrgtGrpMstSn}) {
        state.subscribe.my = state.subscribe.my.filter(x => !(x.actTyCdDcd === actTyCdDcd && x.trgtLrnerId === trgtLrnerId && x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn));
    },
    [MUT_SET_STATICS](state, items) {
        const likes = items.filter(x => x.actTyCdDcd === '2001001');
        const saves = items.filter(x => x.actTyCdDcd === '2001005');

        state.statistics.expiredTime = (new Date().getTime() + (5 * 60 * 1000));
        state.statistics.like = likes.length > 0 ? likes[0].actCnt : 0;
        state.statistics.save = saves.length > 0 ? saves[0].actCnt : 0;
    },
    [MUT_REMOVE_STATICS](state) {
        state.subscribe.expiredTime = 0;
        state.subscribe.my = [];
        state.subscribe.target = [];

        state.statistics.expiredTime = 0;
        state.statistics.like = 0;
        state.statistics.save = 0;
    },
    [MUT_SET_MY_ATTENDANCE_LIST](state, items) {
        state.calendar.myAttends = items;
    },
    [MUT_SET_MY_ATTENDANCE](state, item) {
        state.calendar.myAttends.push(item);
    },
    [MUT_SHOW_ATTENDANCE](state) {
        state.calendar.show = true;
    },
    [MUT_CLOSE_ATTENDANCE](state) {
        state.calendar.show = false
    },


    // learn
    [MUT_SET_MY_LEARN_TARGET_LIST](state, items){
        state.myLearnTargets = items;
    },
    [MUT_SET_LEARN_TARGET_LIST](state, items){
        // 로그인 기준으로 일차 소팅
        items.sort((a, b) => {
            if (a.lastLgnDt < b.lastLgnDt) { return 1;}
            if (a.lastLgnDt > b.lastLgnDt) { return -1;}
            // a must be equal to b
            return 0;
        });
        state.learnTargets = items;
    },
    [MUT_TOGGLE_LRNERS](state){
        state.showLrner = !state.showLrner;
    },
    [MUT_CLOSE_LRNERS](state){
        state.showLrner = false;
    },
    [MUT_SET_LRNER_KEYWORD](state, keyword){
        state.lrnerKeyword = keyword;
    },
    [MUT_SET_EXCELLENT_LRNERS](state, excellents){
        state.excellents = excellents;
    },
    // badge
    [MUT_SHOW_BADGE](state, badges){
        state.badges = badges;
        state.badgeShow = true;
    },
    [MUT_CLOSE_BADGE](state){
        state.badgeShow = false;
    },
    [MUT_SET_MY_BADGE](state, badges){
        state.myBadges = badges;
    },
    [MUT_INIT_MY_BADGE](state){
        state.myBadges = [];
    },
    [MUT_SET_MY_REL](state, {division, items}){
        state[division] = items;
    },
    [MUT_INIT_MY_REL](state){
        state.group = {
            distCrseSn: 0,
            crseNm: null,
            distCrseLrnFldSn: 0,
            distCrseLrnFldNm: null,
            leaderLrnerId: null
        };
        state.knowledge = {distCrseSn: 0};
        state.hrd = {distCrseSn: 0};
    },
    [MUT_SET_GROUP_LIST](state, items){
        // 로그인 순으로 소팅
        items.sort((a, b) => {
            if (a.lastLgnDt < b.lastLgnDt) { return 1;}
            if (a.lastLgnDt > b.lastLgnDt) { return -1;}
            // a must be equal to b
            return 0;
        });
        state.groups = items.map(x => ({...x, isMyGroup: state.group.distCrseSn === x.distCrseSn ? 1 : 0}));
    },

    [MUT_SET_ENROLL_CNT](state, items){
        state.selfAppCnt=items.selfAppCnt;
        state.selfAppJaCnt=items.selfAppJaCnt;
        state.selfAppGiCnt=items.selfAppGiCnt;
        state.selfAppGaCnt=items.selfAppGaCnt;
        state.selfAppIctCnt=items.selfAppIctCnt;
        state.groupAppCnt=items.groupAppCnt;
        state.jisikeAppCnt=items.jisikeAppCnt;
        state.cloudAppCnt=items.cloudAppCnt;
        state.bookAppCnt=items.bookAppCnt;
        state.totAppNonDupCnt=items.totAppNonDupCnt;
    },

    [MUT_SET_POSTSN_LIST](state, {paging, items, opt}){
        state.socBoardTotList.postSns = items;
        state.socBoardTotList.socPaging = paging;
        state.socBoardTotList.opt = opt;
    },

    [MUT_SET_ROUTE_FROM](state, item){
        state.routeFrom.fullPath = item.fullPath;
        state.routeFrom.name = item.name;
        state.routeFrom.query = item.query;
        state.routeFrom.params = item.params;
    },
    [MUT_INIT_ROUTE_FROM](state){
        state.routeFrom.fullPath = '';
        state.routeFrom.name = '';
        state.routeFrom.query = {};
        state.routeFrom.params = {};
    },

};

const actions = {
    [ACT_GET_MY_SOC_BOARD_COUNT](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/my/socs/boards/count', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_BOARD_GROUP_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/boards/group', params, true).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_BOARD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/boards', params, true).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_SOC_BOARD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/my/socs/boards', params, true).then(response => resolve(response))
        });
    },
    [ACT_GET_TOTAL_MY_SOC_BOARD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/total/my/socs/boards', params, true).then(response => resolve(response))
        });
    },
    [ACT_GET_TOTAL_MY_SOC_PUT_BOARD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/total/my/socs/put/boards', params, true).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_ACT_BOARD_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/act/boards', params, true).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_PAGE_LIST](context, socLrnPostSn) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/${socLrnPostSn}/pages`, {}, true).then(response => resolve(response))
        });
    },
    [ACT_UPLOAD_SOC_BOARD_THUMBNAIL](contexts, fileList){
        return ApiService.upload(`/v1/app/socs/thumb/upload`, fileList);
    },
    [ACT_INSERT_SOC_BOARD](context, params) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/socs/boards`, params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_SOC_BOARD](context, params) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/socs/boards`, params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_SOC_BOARD](context, {socLrnPostSn, params}) {
        return new Promise(resolve => {
            ApiService.update('/v1/app/socs/boards', socLrnPostSn, params).then(response => resolve(response))
        });
    },
    [ACT_DELETE_SOC_BOARD](context, socLrnPostSn) {
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/socs/boards`, socLrnPostSn).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_SOC_BOARD_INQ_CNT](context, socLrnPostSn) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/socs/boards/${socLrnPostSn}/inq`, {}).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_ACT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/acts', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_ACT_STAT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/socs/acts/stats', params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_SOC_ACT](context, params) {
        return new Promise(resolve => {
            ApiService.post('/v1/app/socs/acts', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_NTC](context, socLrnNtcSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/socs/ntcs', socLrnNtcSn).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_NTC_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/ntcs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SSL_NTC](context, comNtcSn) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/ssl/ntcs', comNtcSn).then(response => resolve(response))
        });
    },
    [ACT_GET_SSL_NTC_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/ssl/ntcs', params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_SOC_NTC_INQ_CNT](context, socLrnNtcSn) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/socs/ntcs/${socLrnNtcSn}/inq`, {}).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_ACTS_ATTEND_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/acts/attends', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_ACTS_EXCELLENT_LIST]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/acts/excellents', {}).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_CMNT_LIST](context, {socLrnPostSn, params}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/${socLrnPostSn}/cmnts`, params).then(response => resolve(response))
        });
    },
    [ACT_INSERT_SOC_CMNT](context, {socLrnPostSn, params}) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/socs/${socLrnPostSn}/cmnts`, params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_SOC_CMNT](context, {socLrnCmntSn, params}) {
        return new Promise(resolve => {
            ApiService.update('/v1/app/socs/cmnts', socLrnCmntSn, params).then(response => resolve(response))
        });
    },
    [ACT_DELETE_SOC_CMNT](context, socLrnCmntSn) {
        return new Promise(resolve => {
            ApiService.delete('/v1/app/socs/cmnts', socLrnCmntSn).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_SOC_STAT](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/my/socs/stat', params, true).then(response => resolve(response))
        });
    },
    [ACT_GET_REL_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/rel', params).then(response => resolve(response))
        });
    },
    [ACT_GET_UNSPLASH_IMAGE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/unsplashs', params).then(response => resolve(response))
        });
    },
    [ACT_DOWNLOAD_SOC_ATCH](context, {socLrnAtchSn,fileName}){
        ApiService.download('/v1/app/socs/atchs', socLrnAtchSn, null, fileName);
    },
    [ACT_GET_SOC_UV_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/uvs', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_BOOK_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/books', params).then(response => resolve(response))
        });
    },
    [ACT_GET_LEARN_TARGET](context, {lrnerId, lrnTrgtGrpMstSn}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/learns/${lrnerId}/targets/${lrnTrgtGrpMstSn}`, {}).then(response => resolve(response));
        });
    },
    [ACT_GET_LEARN_TARGET_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/learns/targets', params).then(response => resolve(response));
        });
    },
    [ACT_GET_MY_LEARN_TARGET_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/my/learns/targets', params).then(response => resolve(response));
        });
    },

    [ACT_GET_BADGE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/badges', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_BOARD_WRITE_STAT_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/write/stat', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_BOARD_WRITE_STAT_VICE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/write/stat/vice', params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_CNT]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/cnt').then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_EXCELLENT_BOARD_LIST]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/excellent/boards').then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_SUBSCRIBE_BOARD_LIST]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/subscribe/boards').then(response => resolve(response))
        });
    },
    [ACT_GET_GROUP_LRN_E_MNG](context, {resNo, year}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/lrns/${resNo}/year`, year).then(response => resolve(response));
        });
    },
    [ACT_GET_SOC_MY_REL](context, division) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/socs/rel/my', division).then(response => resolve(response))
        });
    },
    // 그룹러닝 내 그룹들(학습자들) 리스트 조회
    [ACT_GET_SOC_REL_GROUP_LIST]() {
        return new Promise(resolve => {
            ApiService.query('/v1/app/socs/rel/groups').then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_REL_GROUP_TARGET](context, lrnerId) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/socs/rel/groups', lrnerId).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_REL_STAT](context, {division, distCrseSn}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/rel/${division}/${distCrseSn}/stat`).then(response => resolve(response))
        });
    },
    [ACT_ENROLL_SSL](context, lrnTrgtGrpMstSn) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/crses/dists/${lrnTrgtGrpMstSn}/ssls`).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_REL_WRITE](context, {division, relSn}) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/socs/rel/${division}/writes`, relSn).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_REL_WRITE_LIST](context, {division, distCrseSn}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/rel/${division}/${distCrseSn}/writes`).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_REF_LIST](context, lrnTrgtGrpMstSn) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/${lrnTrgtGrpMstSn}/refs`).then(response => resolve(response))
        });
    },
    [ACT_CANCEL_SSL](context, lrnTrgtGrpMstSn) {
        return new Promise(resolve => {
            ApiService.delete(`/v1/app/ssl/trgt`, lrnTrgtGrpMstSn).then(response => resolve(response))
        });
    },
    [ACT_GET_SSL_APPLY_CNT](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/applyStt`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_BJUGBNM]() {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/ssl/myBjuGbnm`).then(response => resolve(response))
        });
    },
    [ACT_SHARE_SOC_BOARD](context, {lrnTrgtGrpMstSn, socLrnPostSn, params}) {
        return new Promise(resolve => {
            ApiService.post(`/v1/app/socs/${lrnTrgtGrpMstSn}/boards/${socLrnPostSn}/shares`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_SOC_REL_HRD_WRITE](context, lrnObjDtlDistSn) {
        return new Promise(resolve => {
            ApiService.get(`/v1/app/socs/rel/hrd/writes`, lrnObjDtlDistSn).then(response => resolve(response))
        });
    },
    [ACT_GET_ACT_FEED_LIST](context, {actDivCd, params}) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/actFeed/${actDivCd}`, params).then(response => resolve(response))
        });
    },
    [ACT_GET_SHARE_LIST](context, params) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/boards/share`, params).then(response => resolve(response))
        });
    },
    [ACT_UPDATE_BOOK_MAPNG](context, params){
        return new Promise(resolve => {
            ApiService.put('/v1/app/socs/book/mapng', params).then(response => resolve(response))
        });
    },
    [ACT_GET_MY_SSL_LST](context, year) {
        return new Promise(resolve => {
            ApiService.query(`/v1/app/socs/rel/mySslLst/${year}`).then(response => resolve(response))
        });
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
