<template>
  <!--  is-visible-->
  <div class="board-list-container">
    <!-- board-list-top -->
    <div class="board-list-top">
      <div class="top-column">
        <slot name="search"></slot>
      </div>
      <div class="top-column">
        <div class="list-type">
          <!-- is-active 클래스로 활성화. -->
          <button class="kb-btn-type" :class="{'is-active': boardMode === 'card'}" @click="changeMode(0)">
            <i class="icon-type-thumbnail"></i>
          </button>
          <button class="kb-btn-type" :class="{'is-active': boardMode === 'list'}" @click="changeMode(1)">
            <i class="icon-type-list"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- //board-list-top -->
    <!-- board-list-wrapper -->
    <div class="board-list-wrapper">
      <div v-if="!isLoading && items.length === 0" class="board-search-wrapper">
        <div class="search-empty">
          <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
        </div>
      </div>

      <!-- board-list -->
      <ul v-else :class="boardClass">
        <li v-for="(item, idx) in items" class="board-item" :class="{'type-text': !item.thumb}" :key="idx">
          <SSLCard v-if="boardMode === 'card'"
                   :item="item"
                   :is-group="(item.crseMstSn == '100338'?true:false)"
                   @moveBoard="moveBoard"
          />
          <SSLItem v-else-if="boardMode === 'list'"
                   :item="item"
                   :is-group="(item.crseMstSn == '100338'?true:false)"
                   @moveBoard="moveBoard"
          />
        </li>
      </ul>
      <!-- //board-list -->
      <!-- board-list-actions -->
      <div v-if="paging.hasMore" class="board-list-actions">
        <button class="kb-btn-more" @click="moreFunc">
          <span class="text">더보기</span>
        </button>
      </div>
      <!-- //board-list-actions -->
    </div>
    <!-- //board-list-wrapper -->
  </div>
</template>
<script>

import {computed, ref} from "vue";

import SSLCard from "@/components/ssl/SSLCard";
import SSLItem from "@/components/ssl/SSLItem";
import {BOARD_MODE_KEY} from "@/store/modules/ssl/ssl-index";
import {getToken, saveToken} from "@/assets/js/localstorage.service";

export default {
  name: 'SSLTotBoardList',
  components: {
    SSLCard,
    SSLItem
  },
  props: {
    isLoading: Boolean,
    items: Array,
    paging: Object,
    moreFunc: Function
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {
    const savedMode = getToken(BOARD_MODE_KEY);


    const boardModes = ['card', 'list'];
    const boardMode = ref(savedMode ? savedMode : 'card');
    const boardClass = computed(() => `board-${boardMode.value}-type`);

    const changeMode = (idx) => {
      boardMode.value = boardModes[idx];
      saveToken(BOARD_MODE_KEY, boardModes[idx]);
    }

    const moveBoard = (item) => {
      emit('moveBoard', item);
    }

    return {
      boardModes,
      boardMode,
      boardClass,
      changeMode,
      moveBoard
    }

  }
}
</script>
